import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { FC, useEffect, useState } from 'react'
import { Card, Collapse } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import style from './Styles/index.module.scss'
import {
    faCaretDown,
    faCaretRight,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import { GroupDropdown } from './components/GroupDropdown'
import { OthersPropdown } from './components/OthersPropdown'
import { IProductsVisibility } from './types'
import { SvgIcon } from '../SvgIcon/SvgIcon'
import { IProductGroup } from '../../types/product'

export const ProductsVisibility: FC<IProductsVisibility> = ({
    isReadOnly,
    inputState,
    setInputState,
    isAllProductsAccessible,
    cashedInputState,
}) => {
    const [open, setOpen] = useState(true)
    const [disabled, setDisabled] = useState(false)

    const { productsGroups, products } = useTypedSelector(
        (state) => state.products
    )

    useEffect(() => {
        if (isAllProductsAccessible) {
            setInputState((prev: typeof inputState) => ({
                ...prev,
                visibility: {
                    ...prev.visibility,
                    all_products: true,
                },
            }))
            setDisabled(true)
        } else {
            setInputState((prev: typeof inputState) => ({
                ...prev,
                visibility: { ...cashedInputState.visibility },
            }))
            setDisabled(false)
        }
    }, [isAllProductsAccessible])

    const allProductsHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setInputState((prev: typeof inputState) => ({
                ...prev,
                visibility: {
                    ...prev.visibility,
                    all_products: event.target.checked,
                },
            }))
        }
        if (!event.target.checked) {
            setInputState((prev: any) => ({
                ...prev,
                visibility: {
                    ...prev.visibility,
                    all_products: event.target.checked,
                    groups: [...prev.visibility.groups],
                    products: [...prev.visibility.products],
                },
            }))
        }
    }

    const mainContent: JSX.Element = (
        <>
            <div className={style.notification}>
                <SvgIcon
                    icon={faExclamationCircle}
                    className="me-1 app-color-default"
                />
                <FormattedMessage id="user.userData.notificationText" />
            </div>

            <div className="product-visibility-dropdown">
                <div className="border rounded">
                    <OverlayScrollbarsComponent
                        className="p-3"
                        style={{ height: '350px' }}
                    >
                        <div className="header">
                            <FontAwesomeIcon
                                className="icon"
                                onClick={() => setOpen(!open)}
                                icon={open ? faCaretDown : faCaretRight}
                            />
                            <label className="label">
                                <input
                                    type="checkbox"
                                    className="checkbox-lg pointer me-1"
                                    onChange={allProductsHandle}
                                    checked={
                                        inputState.visibility?.all_products
                                    }
                                    name="all_products"
                                    disabled={disabled}
                                />
                                All products
                            </label>
                        </div>
                        <Collapse in={open}>
                            <div>
                                <div>
                                    {productsGroups.map(
                                        (group: IProductGroup) => (
                                            <GroupDropdown
                                                key={group.group_id}
                                                group={group}
                                                inputState={inputState}
                                                setInputState={setInputState}
                                            />
                                        )
                                    )}
                                </div>
                                <div>
                                    <OthersPropdown
                                        inputState={inputState}
                                        setInputState={setInputState}
                                    />
                                </div>
                            </div>
                        </Collapse>
                    </OverlayScrollbarsComponent>
                </div>
            </div>
        </>
    )

    return (
        <div className="pt-3">
            <Card.Title>
                <p className="label-main text-capital pt-1">
                    <FormattedMessage id="user.userData.chooseVisibleProducts" />
                </p>
            </Card.Title>
            {mainContent}
        </div>
    )
}
