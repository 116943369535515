import React, { FC } from 'react'
import * as yup from 'yup'
import { Form, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { hideModal } from '../../../store/actions/modalActions'
import { createNewUser } from '../../../store/actions/usersActions'
import { CreateUser } from '../../../entity/Users'
import { controllerErrors } from '../../../utils/validationErrors/controllerErrors'
import {
    buildControlsExt,
    passwordInput,
    selectInput,
    textInput,
} from '../../inputs/controls'
import { getUserModel } from '../../../utils/controller'
import { AllProductsAccessTypes, User } from '../../../entity/User'
import {
    createUserPermissions,
    createUserTypes,
} from '../../../utils/userRights'
import { UserTypesDescription } from '../../UserTypesDescription'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { ProductsVisibility } from '../../ProductsVisibility/ProductsVisibility'

const schema = {
    login: yup.string().required(),
    newPassword: yup.string().min(5).required(),
    confirmPassword: yup.string().min(5).required(),
}

const AddNewUserModal: FC = () => {
  const dispatch = useAppDispatch()
  const newUser = new CreateUser()
  const user = new User()
  const newLocal = getUserModel(newUser, false)
  const [inputState, setInputState, touched, setTouched, validationResult] =
    useFormValidation(newLocal, schema, 'newPassword', 'confirmPassword')
  const isAllProductsAccessible = AllProductsAccessTypes?.[inputState.userType]

  const addUserForm = buildControlsExt(
    [
      selectInput({
        name: 'userType',
        label: 'User role',
        isColumn: true,
        className: 'mb-3 label-sm',
        options: createUserTypes(user.permissions),
        tooltipPosition: 'right',
        tooltipText: <UserTypesDescription permissions={user.permissions} />,
      }),
      textInput({
        name: 'login',
        label: 'Username',
        isColumn: true,
        className: 'mb-3',
        isRequired: true,
        errMsg: controllerErrors.adminController(inputState),
      }),
      passwordInput({
        name: 'newPassword',
        label: 'Password',
        isColumn: true,
        isRequired: true,
        className: 'mb-3',
        inputType: 'password',
        errMsg: controllerErrors.adminControllerNewPassword(inputState),
      }),
      passwordInput({
        name: 'confirmPassword',
        label: 'Repeat password',
        isColumn: true,
        className: 'mb-3',
        isRequired: true,
        inputType: 'password',
        errMsg: controllerErrors.adminControllerRepeatPassword(inputState),
      }),
    ],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
    validationResult
  )

  const handleOnSubmit = (): void => {
    const user = {
      login: inputState.login,
      password: inputState.confirmPassword,
      permissions: createUserPermissions(inputState.userType),
      visibility: { ...inputState.visibility },
    }
    dispatch(createNewUser(user, setInputState, newLocal, setTouched))
  }

  const handleClose = (): void => {
    dispatch(hideModal())
    setInputState(newLocal)
    setTouched({})
  }

  const IsAbleToConfirm = !!Object.keys(validationResult).length

  return (
    <>
        <div className="bg-blur" />
        <Modal
          centered
          className="add-user-modal"
          size="lg"
          show={true}
          onHide={handleClose}
        >
          <Modal.Header closeButton className="px-4 py-3">
            <Modal.Title>
              <FormattedMessage id="add-new-user" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4 py-3">
            <div className="blockquote m-0">{addUserForm}</div>
            <ProductsVisibility
              inputState={inputState}
              setInputState={setInputState}
              isReadOnly={false}
              isAllProductsAccessible={isAllProductsAccessible}
              cashedInputState={inputState}
            />
          </Modal.Body>
          <Modal.Footer className="px-4 py-3">
          <Form className="d-flex justify-content-between me-0">
              <button
                className="app-btn-cancel btn-primary app-btn-main ms-auto me-2"
                type="button"
                onClick={handleClose}
              >
                <FormattedMessage id="cancel" />
              </button>
              <button
                className="app-btn-main app-btn-apply"
                type="button"
                disabled={IsAbleToConfirm}
                onClick={handleOnSubmit}
              >
                <FormattedMessage id="confirm" />
              </button>
          </Form>
          </Modal.Footer>
        </Modal>
    </>
  )
}

export default AddNewUserModal
