export interface IDateTimeProps {
  data: any
  dataSchema: any
  name: string
  dataPath?: string[]
}

export enum TimeFormats {
  FULL_DATE_FULL_TIME = 'YYYY-MM-DD hh:mm:ss.fff',
  DATE_HOURS_MINUTES_SECONDS = 'YYYY-MM-DD hh:mm:ss',
  DATE_HOURS_MINUTES = 'YYYY-MM-DD hh:mm',
  DATE_AND_HOURS = 'YYYY-MM-DD hh',
  FULL_TIME = 'hh:mm:ss.fff',
  HOURS_MINUTES_SECONDS = 'hh:mm:ss',
  HOURS_MINUTES = 'hh:mm',
  HOURS = 'hh',
  DATE = 'YYYY-MM-DD',
  UNSUPPORTED_FORMAT = 'unsupported_format'
}
