import { ProductStatus, ProductType } from '../../../entity/Product'
import { FormattedMessage } from 'react-intl'
import style from '../Styles/index.module.scss'
import React from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import { changeProductStatus } from '../../../store/actions/products/productsActions'

export interface IProductCardProductStatus {
    guid: string
    status: ProductStatus
    productType: ProductType | null | undefined
}

const ProductCardProductStatus = (props: IProductCardProductStatus) => {
    const { guid, status, productType } = props
    const dispatch = useAppDispatch()
    const newStatus = status === ProductStatus.ACTIVE ? ProductStatus.IDLE : ProductStatus.ACTIVE

    const confirmMsg = productType === ProductType.SERVICE
        ? <FormattedMessage id="productCard.serviceProductChangeStatus" />
        : productType === ProductType.JOB
        ? <FormattedMessage id="productCard.jobProductChangeStatus" /> : ''

    let statusName, statusStyle, btnName;
    switch(status) {
        case ProductStatus.ACTIVE:
            statusName = ProductStatus.ACTIVE;
            statusStyle = 'active';
            btnName = productType === ProductType.SERVICE
                ? 'Pause' : productType === ProductType.JOB ? 'Stop' : '';
            break;
        case ProductStatus.IDLE:
            statusName = 'On standby'
            statusStyle = 'idle'
            btnName = btnName = productType === ProductType.SERVICE
                ? 'Resume' : productType === ProductType.JOB ? 'Start' : '';
            break;
        case ProductStatus.CHANGING_TO_IDLE:
            statusName = 'Changing to Standby'
            statusStyle = 'changing'
            btnName = btnName = productType === ProductType.SERVICE
                ? 'Pause' : productType === ProductType.JOB ? 'Stop' : ''
            break;
        case ProductStatus.CHANGING_TO_ACTIVE:
            statusName = 'Changing to Active'
            statusStyle = 'changing'
            btnName = btnName = btnName = productType === ProductType.SERVICE
                ? 'Resume' : productType === ProductType.JOB ? 'Start' : '';
            break;
        default:
            statusName = ProductStatus.INACTIVE
            statusStyle = 'inactive'
            btnName = btnName = btnName = btnName = productType === ProductType.SERVICE
                ? 'Resume' : productType === ProductType.JOB ? 'Start' : '';
    }

    const changeProductStatusHandler = async () => {
        if (status === ProductStatus.ACTIVE) {
            dispatch(showModal(ModalTypes.MAIN_MODAL, {
                body: confirmMsg,
                onSubmit: () => dispatch(changeProductStatus(guid, newStatus))
            }))
            return
        }
        dispatch(changeProductStatus(guid, newStatus))
    }
    return (
        <div className={style.productStatus}>
            <div className="flex">
                <span className="app-color-text fw-500 me-1">
                    <FormattedMessage id="productCard.status" />
                </span>
                <span>{statusName}</span>
                <span className={style[statusStyle]} />
            </div>

            {productType && productType !== ProductType.PLUGIN && status !== ProductStatus.INACTIVE ? (
                <button
                    onClick={changeProductStatusHandler}
                    disabled={status.includes('Changing')}
                    className="app-btn-apply app-btn-main app-btn-reset"
                >
                    {btnName}
                </button>
            ) : (
                <></>
            )}
        </div>
    )
}

export default ProductCardProductStatus
