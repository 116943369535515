import * as React from 'react'
import { Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { FormattedMessage, useIntl } from 'react-intl'
import 'react-datepicker/dist/react-datepicker.css'
import { getLabel } from '../../../validators'
import PromptBtn from '../../buttons/PromptBtn/PromptBtn'
import { IDateTimePicker } from '../types'
import { TimeFormats } from '../../models/DateTime/types'
import { transformDate } from '../../models/DateTime/utils'
import { getDatePickerValues } from './getDatePickerValues'

const DateTimePicker: React.FC<IDateTimePicker> = ({
    state,
    setState,
    valueKey,
    messageKey,
    schema,
    timeSchema,
    timeIntervals,
    className,
    description,
    isColumn,
    minDate,
    maxDate,
    disabled,
    isRequired,
    timeFormat,
    isLabelHidden,
    errMsg,
    touched,
    setTouched,
}) => {
    const intl = useIntl()

    const onChange = (date: Date): void => {
        const formattedDate = transformDate(timeFormat, date, timeFormat)
        setState({ ...state, [valueKey]: formattedDate })
    }

    const datepickerValues = getDatePickerValues(
        timeFormat as TimeFormats,
        state,
        valueKey
        // minDate,
        // maxDate,
    )

    if (datepickerValues.unsupported) {
        return (
            <div
                style={{ maxWidth: '600px' }}
                className={`${className} d-flex flex-1 mt-1 mb-1 align-items-center justify-content-between`}
            >
                <Form.Label
                    style={{ maxWidth: '30%' }}
                    className={`label-main mt-1 text-capital`}
                >
                    {getLabel(messageKey)}:{' '}
                </Form.Label>
                Unsupported datetime/time format
            </div>
        )
    }

    return (
        <Form.Group
            style={{ maxWidth: '600px' }}
            className={`${className} d-flex flex-1 mt-1 mb-1 align-items-start justify-content-between`}
        >
            {!isLabelHidden ? (
                <Form.Label
                    style={{ maxWidth: '30%' }}
                    className={`label-main mt-1 text-capital`}
                >
                    {getLabel(messageKey)}:{' '}
                    {isRequired && (
                        <span className="app-color-text label-required-mark">
                            *
                        </span>
                    )}
                </Form.Label>
            ) : null}
            <div
                style={{ width: '70%' }}
                className={`d-flex ${
                    !isColumn ? 'flex-1' : ''
                } p-relative flex-column`}
            >
                <div className="d-flex align-items-center justify-content-between w-100">
                    <DatePicker
                        timeCaption="time"
                        onChange={onChange}
                        locale={intl.locale}
                        disabled={disabled ?? false}
                        minDate={datepickerValues.minDate}
                        maxDate={datepickerValues.maxDate}
                        minTime={datepickerValues.minTime}
                        maxTime={datepickerValues.maxTime}
                        timeIntervals={timeIntervals || 60}
                        selected={datepickerValues.selected}
                        dateFormat={datepickerValues.dateFormat}
                        timeFormat={datepickerValues.timeFormat}
                        showTimeSelect={timeFormat !== TimeFormats.DATE}
                        placeholderText={datepickerValues.placeholderText}
                        showTimeSelectOnly={datepickerValues.showTimeSelectOnly}
                        className={`form-control ${errMsg ? 'is-invalid' : ''}`}
                    />
                    {description && (
                        <PromptBtn
                            className="mt-1 input-tooltip"
                            prompt={description ?? ''}
                        />
                    )}
                </div>
                {errMsg && (
                    <p
                        style={{
                            color: '#dc3545',
                            marginBottom: '0',
                            fontSize: '0.9rem',
                        }}
                    >
                        <FormattedMessage id={errMsg?.message} />
                    </p>
                )}
            </div>
        </Form.Group>
    )
}

export default DateTimePicker
