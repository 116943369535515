import React, { FC } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hideModal } from '../../store/actions/modalActions'

const MainModal: FC = () => {
    const { data } = useTypedSelector((state) => state.modal)
    const dispatch = useAppDispatch()

    const handleClose = (): void => {
        dispatch(hideModal())
    }

    const handleOnSubmit = (): void => {
        data.onSubmit()
        handleClose()
    }

    return (
        <>
            <div className="bg-blur" />
            <Modal
                centered
                show={true}
                onHide={handleClose}
            >
                <Modal.Header
                    className="px-4 py-3"
                    closeButton
                >
                    <Modal.Title>
                        {data.title ? (
                            data.title
                        ) : (
                            <FormattedMessage id="please-confirm" />
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4 py-3">
                    <div className="blockquote m-0 word-break">{data.body}</div>
                </Modal.Body>
                <Form>
                    <Modal.Footer className="px-4 py-3">
                        <Button
                            className="app-btn-main"
                            variant="secondary"
                            type="submit"
                            onClick={handleClose}
                        >
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button
                            variant="primary"
                            className="app-btn-main app-btn-apply me-0"
                            type="submit"
                            onClick={handleOnSubmit}
                        >
                            <FormattedMessage id="confirm" />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default MainModal
