import React, { useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import { ICheckBoxInput } from './types'

const CheckboxInput: React.FC<ICheckBoxInput> = (props) => {
    const {
        state,
        setState,
        name,
        label,
        isDisabled,
        className,
        description,
        checkboxType,
        touched,
        setTouched,
        isNullable,
    } = props
    const id = `checkboxId-${name}-${Math.random()}`
    const target: any = useRef(null)

    const handleChange = (event: any): void => {
        if (!isNullable || checkboxType === 'switch') {
            setState({
                ...state,
                [name]: event.target.checked,
            })
        }

        if (isNullable && checkboxType !== 'switch') {
            if (state?.[name] === null) {
                setState({
                    ...state,
                    [name]: false,
                })
            } else if (state?.[name] === false) {
                setState({
                    ...state,
                    [name]: true,
                })
            } else {
                target.current.indeterminate = true
                setState({
                    ...state,
                    [name]: null,
                })
            }
        }

        setTouched &&
            setTouched({
                ...touched,
                [name]: true,
            })
    }

    useEffect(() => {
        if (isNullable && state?.[name] === null) {
            target.current.indeterminate = true
        }
    }, []) // eslint-disable-line

    switch (checkboxType) {
        case 'switch':
            return (
                <div
                    style={{ background: 'transparent' }}
                    className={`${className} m-1 d-flex align-items-center justify-content-center`}
                >
                    <Form.Check
                        type="switch"
                        id={id}
                        label={label || ''}
                        onChange={handleChange}
                        checked={state?.[name]}
                        className="text-capital pointer"
                        disabled={!!isDisabled}
                    />
                </div>
            )

        case 'switch-md':
            return (
                <div
                    style={{ background: 'transparent' }}
                    className={`${className} m-1 d-flex align-items-center`}
                >
                    <Form.Check
                        style={{ background: 'transparent' }}
                        type="switch"
                        id={id}
                        label={label || ''}
                        onChange={handleChange}
                        checked={state?.[name]}
                        className="text-capital custom-switch-md pointer"
                        disabled={!!isDisabled}
                    />
                </div>
            )

        case 'small':
            return (
                <div className={`${className} m-1 d-flex align-items-center`}>
                    <label className="pointer">
                        <span
                            className={`d-flex align-items-center ${
                                description && 'mt-1 me-1'
                            }`}
                        >
                            <span
                                className={`me-1 label-small text-capital ${
                                    state?.[name] ? '' : 'text-secondary'
                                } `}
                            >
                                {label}:
                            </span>
                            <input
                                ref={target}
                                id={id}
                                type="checkbox"
                                className="checkbox-sm mt-1 pointer"
                                onChange={handleChange}
                                checked={state?.[name]}
                                disabled={!!isDisabled}
                                name={name}
                            />
                        </span>
                    </label>
                    {description && <PromptBtn prompt={description ?? ''} />}
                </div>
            )

        default:
            return (
                <div
                    className={`${className} m-1 mt-2 mb-2 d-flex align-items-center`}
                >
                    <label className="pointer d-block">
                        <span
                            className={`d-flex align-items-center ${
                                description && 'mt-2 me-1'
                            }`}
                        >
                            <input
                                ref={target}
                                id={id}
                                type="checkbox"
                                className="checkbox-lg pointer"
                                onChange={handleChange}
                                checked={state?.[name]}
                                disabled={!!isDisabled}
                                name={name}
                            />
                            <span
                                className={`label-main ms-2 text-capital ${
                                    state?.[name] ? '' : 'text-secondary'
                                } `}
                            >
                                {label}
                            </span>
                        </span>
                    </label>
                    {description && (
                        <PromptBtn
                            className="mt-2"
                            prompt={description ?? ''}
                        />
                    )}
                </div>
            )
    }
}

export default CheckboxInput
